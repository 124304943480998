"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
var _request = _interopRequireDefault(require("@/utils/request"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _formatMixin = require("@/utils/formatMixin");
var _default = exports.default = {
  components: {
    Pagination: _index.default
  },
  mixins: [_formatMixin.formatMixin],
  data: function data() {
    return {
      search: '',
      // 顶部搜索框form数据
      formInline: {
        userphone: '',
        orderNo: '',
        username: '',
        start_time: '',
        end_time: '',
        g_date: '0',
        status: '0'
      },
      datenum: '',
      // 订单列表数据
      dataList: {
        count: 0
      },
      listLoading: false,
      listQuery: {
        page: 1,
        page_size: 10
      }
    };
  },
  created: function created() {
    this.getOrderList();
  },
  computed: {
    start_time: function start_time() {
      return this.datenum[0] ? this.formatDate(this.datenum[0]) : '';
    },
    end_time: function end_time() {
      return this.datenum[1] ? this.formatDate(this.datenum[1]) : '';
    }
  },
  methods: {
    // 格式化日期,需要加上时分秒的数据
    formatDate: function formatDate(date) {
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
      var day = String(date.getDate()).padStart(2, '0');
      var hour = String(date.getHours()).padStart(2, '0');
      var minute = String(date.getMinutes()).padStart(2, '0');
      var second = String(date.getSeconds()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute, ":").concat(second);
    },
    getDateFiveDaysAgo: function getDateFiveDaysAgo(days) {
      var today = new Date(); // 获取当前日期
      today.setDate(today.getDate() - days); // 计算五日前的日期

      // 格式化日期为 YYYY-MM-DD
      var year = today.getFullYear();
      var month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
      var day = String(today.getDate()).padStart(2, '0');
      if (days == 8) {
        return "".concat(year, "-").concat(month, "-").concat(day) + '前';
      }
      return "".concat(year, "-").concat(month, "-").concat(day);
    },
    // 查看订单详情
    handleClick: function handleClick(item) {
      this.$router.push({
        name: 'orderDetail',
        query: {
          id: item.id
        }
      });
    },
    // 过滤租赁合同签署状态
    filterEsign: function filterEsign(value, row) {
      return row.is_esign_contract == value;
    },
    // 过滤代扣协议签署状态
    filterAlipay: function filterAlipay(value, row) {
      return row.is_alipay_contract == value;
    },
    // 过滤订单状态
    filterStatus: function filterStatus(value, row) {
      return row.is_cancel == value;
    },
    // 搜索方法——根据关键字搜索
    goSearch: function goSearch() {
      var _this = this;
      this.listLoading = true;
      // 发请求提交数据给后台
      (0, _request.default)({
        url: "/system/order/getList/",
        method: 'post',
        data: {
          userphone: this.formInline.userphone,
          orderNo: this.formInline.orderNo,
          username: this.formInline.username,
          start_time: this.start_time,
          end_time: this.end_time,
          g_date: this.formInline.g_date,
          status: this.formInline.status,
          shop: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.data) {
          _this.dataList = response.data.data;
        }
        _this.listLoading = false;
      }).catch(function (error) {
        _this.listLoading = false;
      });
    },
    // 获取订单列表
    getOrderList: function getOrderList() {
      var _this2 = this;
      this.listLoading = true;

      // 发请求提交数据给后台
      (0, _request.default)({
        url: "/system/order/getList/",
        method: 'post',
        data: {
          userphone: this.formInline.userphone,
          orderNo: this.formInline.orderNo,
          username: this.formInline.username,
          start_time: this.start_time,
          end_time: this.end_time,
          shop: this.$user.data.shop,
          g_date: this.formInline.g_date,
          status: this.formInline.status,
          page: this.listQuery.page,
          page_size: this.listQuery.page_size
        }
      }).then(function (response) {
        if (response.data) {
          _this2.dataList = response.data.data;
        }
        _this2.listLoading = false;
      }).catch(function (error) {
        _this2.listLoading = false;
      });
    },
    // 重置
    resetForm: function resetForm() {
      this.formInline.userphone = '';
      this.formInline.orderNo = '';
      this.formInline.username = '';
      this.formInline.start_time = '';
      this.formInline.end_time = '';
      this.datenum = [];
      this.getOrderList();
    }
  }
};