"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _request = _interopRequireDefault(require("@/utils/request"));
var _addTagForm = _interopRequireDefault(require("./addTagForm.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _auth = require("@/utils/auth");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  components: {
    addTagForm: _addTagForm.default,
    Pagination: _index.default
  },
  data: function data() {
    return {
      dialogFormVisible: false,
      //是否显示新增产品form
      dialogFormVisible2: false,
      listLoading: true,
      pk: 0,
      tag_list: [],
      old_tag_list: [],
      shop_list: [],
      listQuery: {
        page: 1,
        page_size: 10
      },
      value: '',
      tag_id: 0,
      pic_header: 'Bearer ' + (0, _auth.getToken)(),
      name: ''
    };
  },
  created: function created() {
    this.getTagList();
  },
  methods: {
    // 顶部添加按钮--显示新增产品form
    handleAdd: function handleAdd() {
      this.dialogFormVisible = true;
    },
    reload: function reload() {
      // 刷新页面
      window.location.reload();
    },
    getTagList: function getTagList() {
      var _this = this;
      (0, _request.default)({
        url: "/ReportShopFlag/",
        method: 'get',
        params: {
          page: 1,
          page_size: 100
        }
      }).then(function (res) {
        var results = [];
        _this.old_tag_list = res.data.results;
        res.data.results.forEach(function (item) {
          results[item.id] = item.name;
        });
        console.log(results);
        _this.tag_list = results;
        console.log(res);
        _this.getShopList(0);
      });
    },
    getShopList: function getShopList(type) {
      var _this2 = this;
      if (type == 1) {
        this.listQuery.page = 1;
        this.listQuery.page_size = 10;
      }
      (0, _request.default)({
        url: "/system/report/get_report_shop/",
        method: 'get',
        params: {
          page: this.listQuery.page,
          page_size: this.listQuery.page_size,
          name: this.name
        },
        image_src: ''
      }).then(function (res) {
        var aa = [];
        for (var i = 0; i < res.data.results.length; i++) {
          aa[res.data.results[i].id] = 0;
        }
        console.log(aa);
        _this2.dialogFormVisible1 = aa;
        _this2.shop_list = res.data;
        console.log(_this2.shop_list.count);
        // this.getImage()
      });
    },
    showSelect: function showSelect(e) {
      this.dialogFormVisible2 = true;
      this.tag_id = e;
    },
    // 添加广告类别
    addTag: function addTag(val) {
      var _this3 = this;
      // console.log(val, '新增类别')
      // 1.加载中
      this.listLoading = true;
      // 2.发送请求，添加产品
      (0, _request.default)({
        url: "/ReportShopFlag/",
        method: 'post',
        params: {},
        // url参数
        data: _objectSpread(_objectSpread({}, val), {}, {
          name: val.name
        }) // 请求体参数
      }).then(function (response) {
        if (response.code === 200 || 201) {
          // console.log('新增成功',response.data.id);

          // 3.提示添加成功
          _this3.$message({
            message: "\u6DFB\u52A0\u6210\u529F",
            type: 'success'
          });
          // 关闭loading
          _this3.listLoading = false;
          // 4.关闭新增产品form
          _this3.dialogFormVisible = false;
          _this3.reload();
        } else {
          _this3.$message({
            message: "\u6DFB\u52A0\u5931\u8D25",
            type: 'error'
          });
        }
      }).catch(function (err) {
        console.log(err, '新增失败');
      });
    },
    handleClose: function handleClose(id, tag_id) {
      var _this4 = this;
      (0, _request.default)({
        url: "/system/report/tag_shop_del/",
        method: 'put',
        data: {
          id: id,
          tag_id: tag_id
        }
      }).then(function (res) {
        if (res.code == 200) {
          _this4.reload();
        }
        console.log(res);
      });
      // console.log(id)
      // console.log(tag_id)
    },
    addShopTag: function addShopTag() {
      var _this5 = this;
      (0, _request.default)({
        url: "/system/report/tag_shop_add/",
        method: 'put',
        data: {
          id: this.tag_id,
          tag_id: this.value
        }
      }).then(function (res) {
        if (res.code == 200) {
          _this5.reload();
        }
        console.log(res);
      });
      // console.log(this.value)
      // console.log(id)
    },
    // 取消新增产品
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.reload();
    } // getImage(){
    //   request({
    //     url: '/getImageView?id=1&path=report_shop',
    //     method: 'get',
    //     data: {}
    //   }).then(res => {
    //     this.image_src=res
    //     // if(res.code==200){
    //     //   this.
    //     //   this.reload()
    //     // }
    //     // console.log(res)
    //   })
    //   // console.log(this.value)
    //   // console.log(id)
    // },
  }
};