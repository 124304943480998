"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request1"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _default = exports.default = {
  components: {
    Pagination: _index.default
  },
  data: function data() {
    return {
      listLoading: true,
      pk: 0,
      list: [],
      listQuery: {
        page: 1,
        page_size: 10
      },
      isLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    setEwm: function setEwm() {
      var _this = this;
      console.log(this.$user.data.shop);
      (0, _request.default)({
        url: "/system/report/set_ewm/?shop_id=" + this.$user.data.shop,
        method: 'get',
        data: {}
      }).then(function (response) {
        console.log(response.data);
        _this.$message({
          dangerouslyUseHTMLString: true,
          message: '<img style="width:200px;height:200px" src="' + response.data + '"></img>',
          duration: 0,
          showClose: true
        });
      });
    },
    handleClick: function handleClick(id) {
      this.$router.push({
        path: '/report/reportGetDetail',
        query: {
          id: id
        }
      });
    },
    handleCreate: function handleCreate(id) {
      var _this2 = this;
      console.log(id);
      this.isLoading = true;
      (0, _request2.default)({
        url: '/create_report',
        method: 'post',
        data: {
          record_id: id
        }
      }).then(function (response) {
        if (response.code == 1) {
          _this2.isLoading = false;
          _this2.getList();
        }
        // console.log(response.data)
      }).catch(function (error) {
        _this2.isLoading = false;
        // console.log(error)
      });
    },
    reload: function reload() {
      // 刷新页面
      window.location.reload();
    },
    getList: function getList() {
      var _this3 = this;
      (0, _request.default)({
        url: "/ReportGet/",
        method: 'get',
        params: {
          page: 1,
          page_size: 100
        }
      }).then(function (res) {
        _this3.list = res.data;
        _this3.listLoading = false;
        // console.log(res)
      });
    }
  }
};