"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    perms: ['admin','editor']    control the page perms (you can set multiple perms)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all perms can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/register',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/register'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '首页',
      icon: 'dash',
      perms: ['dashboard']
    }
  }]
}, {
  path: '/changepassword',
  component: _layout.default,
  redirect: '/changepassword',
  name: 'ChangePW',
  meta: {
    title: '修改密码',
    icon: 'tree'
  },
  hidden: true,
  children: [{
    path: '',
    name: 'ChangePassword',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/changepassword'));
      });
    },
    meta: {
      title: '修改密码',
      noCache: true,
      icon: ''
    },
    hidden: true
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user perms
 */
var asyncRoutes = exports.asyncRoutes = [{
  path: '/goods',
  component: _layout.default,
  redirect: '/goods/goodList',
  name: 'good',
  meta: {
    title: '产品管理',
    icon: 'output'
  },
  children: [{
    path: 'goodList',
    name: 'goodList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/good/goodList.vue'));
      });
    },
    meta: {
      title: '产品列表',
      icon: 'product',
      perms: ['goodList']
    }
  }, {
    path: 'goodDetail',
    name: 'goodDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/good/goodDetail.vue'));
      });
    },
    meta: {
      title: '产品详情',
      icon: 'output',
      perms: ['goodDetail']
    },
    hidden: true
  }]
}, {
  path: '/Order',
  component: _layout.default,
  redirect: '/Order/OrderList',
  name: 'order',
  meta: {
    title: '订单管理',
    icon: 'drag'
  },
  children: [{
    path: 'orderList',
    name: 'orderList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/orderList.vue'));
      });
    },
    meta: {
      title: '订单列表',
      icon: 'order',
      perms: ['orderList']
    }
  }, {
    path: 'orderDetail',
    name: 'orderDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/orderDetail.vue'));
      });
    },
    meta: {
      title: '订单详情',
      icon: 'drag',
      perms: ['orderDetail']
    },
    hidden: true
  }]
}, {
  path: '/shop2',
  component: _layout.default,
  redirect: '/shop2/shopList',
  name: 'shop',
  meta: {
    title: '店铺管理',
    icon: 'edit'
  },
  children: [{
    path: 'shopList',
    name: 'shopList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/shopList.vue'));
      });
    },
    meta: {
      title: '店铺列表',
      icon: 'shop1',
      perms: ['shopList']
    }
  }, {
    path: 'shopDetail',
    name: 'shopDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/shopDetail.vue'));
      });
    },
    meta: {
      title: '店铺详情',
      icon: 'edit',
      perms: ['shopDetail']
    },
    hidden: true
  }]
}, {
  path: '/shop',
  component: _layout.default,
  redirect: '/shop/preRegisterWFL',
  name: 'good',
  meta: {
    title: '审核管理',
    icon: 'form',
    perms: ['preRegisterWFL']
  },
  children: [{
    path: 'preRegisterWFL',
    name: 'preRegisterWFL',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/preRegisterWFL.vue'));
      });
    },
    meta: {
      title: '预注册审核',
      icon: 'preregister',
      perms: ['preRegisterWFL']
    }
  }, {
    path: 'preRegisterDetail',
    name: 'preRegisterDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/preRegisterDetail.vue'));
      });
    },
    meta: {
      title: '审核详情',
      icon: 'form',
      perms: ['preRegisterDetail']
    },
    hidden: true
  }]
}, {
  path: '/OrderHk',
  component: _layout.default,
  redirect: '/OrderHk/OrderHk',
  name: 'OrderHk',
  meta: {
    title: '财务管理',
    icon: 'finance'
  },
  children: [{
    path: 'OrderHk',
    name: 'OrderHk',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/business/finance.vue'));
      });
    },
    meta: {
      title: '账户明细',
      icon: 'account',
      perms: ['OrderHk']
    }
  }, {
    path: 'Withdrawal',
    name: 'Withdrawal',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/business/withdrawal.vue'));
      });
    },
    meta: {
      title: '提现管理',
      icon: 'withdral',
      perms: ['Withdrawal']
    }
  }]
}, {
  path: '/system',
  component: _layout.default,
  redirect: '/system/user',
  name: 'System',
  meta: {
    title: '系统管理',
    icon: 'system',
    perms: ['system_manage']
  },
  children: [{
    path: 'user',
    name: 'User',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/user.vue'));
      });
    },
    meta: {
      title: '用户管理',
      icon: 'user1',
      perms: ['user_manage']
    }
  }, {
    path: 'role',
    name: 'Role',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/role'));
      });
    },
    meta: {
      title: '角色管理',
      icon: 'role',
      perms: ['role_manage']
    }
  }, {
    path: 'perm',
    name: 'Perm',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/perm'));
      });
    },
    meta: {
      title: '权限菜单',
      icon: 'perm',
      perms: ['perm_manage']
    }
  }]
}, {
  path: '/StaffManage',
  component: _layout.default,
  redirect: '/StaffManage/StaffManage',
  name: 'StaffManage',
  meta: {
    title: '员工管理',
    icon: 'star',
    perms: ['StaffManage']
  },
  children: [{
    path: 'StaffManage',
    name: 'StaffManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/staff.vue'));
      });
    },
    meta: {
      title: '员工管理',
      icon: 'staff',
      perms: ['StaffManage']
    }
  }]
}, {
  path: '/PurchaserManage',
  component: _layout.default,
  redirect: '/PurchaserManage/PurchaserManage',
  name: 'PurchaserManage',
  meta: {
    title: '用户管理',
    icon: 'skill',
    perms: ['PurchaserManage']
  },
  hidden: true,
  children: [{
    path: 'PurchaserManage',
    name: 'PurchaserManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/purchaser/purchaser.vue'));
      });
    },
    meta: {
      title: '用户管理',
      icon: 'skill',
      perms: ['PurchaserManage']
    },
    hidden: true
  }]
}, {
  path: '/Ad',
  component: _layout.default,
  redirect: '/Ad/Ad',
  name: 'Ad',
  meta: {
    title: '广告管理',
    icon: 'skill',
    perms: ['Ad']
  },
  children: [{
    path: 'Ad',
    name: 'Ad',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/ad/adList.vue'));
      });
    },
    meta: {
      title: '广告管理',
      icon: 'skill',
      perms: ['Ad']
    },
    hidden: true
  }, {
    path: 'AdCate',
    name: 'AdCate',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/ad/adCate.vue'));
      });
    },
    meta: {
      title: '广告类别管理',
      icon: 'ad',
      perms: ['AdCate']
    }
  }, {
    path: 'adList',
    name: 'adList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/ad/adList.vue'));
      });
    },
    meta: {
      title: '广告配置',
      icon: 'skill',
      perms: ['AdCate']
    },
    hidden: true
  }]
}, {
  path: '/Report',
  component: _layout.default,
  redirect: '/Report/Report',
  name: 'Report',
  meta: {
    title: '报告管理',
    icon: 'skill',
    perms: ['Report']
  },
  children: [{
    path: 'ReportShop',
    name: 'ReportShop',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/report/reportShop.vue'));
      });
    },
    meta: {
      title: '报告商家管理',
      icon: 'skill',
      perms: ['ReportShop']
    }
  }, {
    path: 'ReportGet',
    name: 'ReportGet',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/report/reportGet.vue'));
      });
    },
    meta: {
      title: '报告管理',
      icon: 'ad',
      perms: ['ReportGet']
    }
  }, {
    path: 'ReportGetDetail',
    name: 'ReportGetDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/report/reportDetail.vue'));
      });
    },
    meta: {
      title: '报告详情',
      icon: 'ad',
      perms: ['ReportGet']
    },
    hidden: true
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;